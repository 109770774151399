/*!
 * Material themes by djibe for various plugins (standalone)
 */
.dataTables_wrapper {
  margin-bottom: 2rem;
}

.dataTables_wrapper .dataTable {
  margin-bottom: 0;
}

.dataTables_wrapper > .data-table {
  width: 100%;
}

.dataTable thead td,
.dataTable thead th {
  cursor: pointer;
  text-transform: lowercase;
}

.dataTable thead td::first-letter,
.dataTable thead th::first-letter {
  text-transform: uppercase;
}

.dataTable .dataTables_empty {
  font-weight: 500;
}

@media print {
  .dataTable tbody td,
  .dataTable tbody th {
    height: 2.25rem;
  }
  .dataTable tfoot td,
  .dataTable tfoot th,
  .dataTable thead td,
  .dataTable thead th {
    height: 2.5rem;
  }
}

.dataTable thead th.sorting_asc,
.dataTable thead th.sorting_desc {
  position: relative;
}

.dataTable thead th.sorting_asc::after,
.dataTable thead th.sorting_desc::after {
  opacity: 0.54 !important;
}

.dataTable thead th.sorting:hover::after {
  opacity: 0.27;
}

.dataTable thead th[class*='sorting']::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='18' height='18' viewBox='0 0 24 24'%3E%3Cpath d='M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z'/%3E%3C/svg%3E");
  height: 18px;
  margin: 0 0.25rem;
  opacity: 0;
  position: absolute;
  transform: rotate(0deg);
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1), transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 18px;
}

.dataTable thead th[class*='sorting']:focus {
  outline: 0;
}

.dataTable thead th.sorting_desc::after {
  transform: rotate(-180deg);
}

.dataTables_filter {
  display: flex;
  margin-bottom: 1rem;
}

.dataTables_filter > label {
  display: flex;
  margin-left: auto;
  min-width: 280px;
  position: relative;
}

.dataTables_filter > label::before {
  background: no-repeat center/24px url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath fill='%23757575' d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3C/svg%3E");
  content: '';
  height: 46px;
  position: absolute;
  left: 1rem;
  width: 24px;
}

.dataTables_filter > label input {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 11px 16px 11px 48px;
  transition: border 150ms linear, box-shadow 150ms linear;
}

.dataTables_filter > label input:hover {
  border-color: rgba(0, 0, 0, 0.38);
  box-shadow: none;
}

.dataTables_filter > label input:focus {
  border-color: transparent;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

@media screen and (max-width: 960px) {
  .dataTables_filter > label {
    margin-left: 0;
    width: 100%;
  }
}

.dataTables_wrapper .bottom {
  align-items: center;
  border-top: 1px solid #e1e1e1;
  display: flex;
  min-height: 52px;
  padding: 0 2px 0 1rem;
}

@media screen and (max-width: 768px) {
  .dataTables_wrapper .bottom {
    flex-wrap: wrap;
  }
}

.dataTables-svg {
  display: inline-block;
  fill: currentColor;
  font-size: 1.5rem;
  height: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 24px;
}

.dt-buttons .btn-icon:hover .dataTables-svg {
  color: #212121;
}

.dt-buttons .dataTables-svg {
  color: #757575;
}

.dt-buttons > .btn-icon > span {
  display: flex;
}

.dataTables_length {
  margin-right: 2rem;
  margin-left: auto;
}

.dataTables_length .custom-select {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0.5rem;
  padding: 0.375rem 30px calc(0.375rem - 1px) 1rem;
  width: auto;
}

.dataTables_length .custom-select:hover {
  border-color: rgba(0, 0, 0, 0.42);
}

.dataTables_length .custom-select:focus {
  border-color: #6200ee;
  box-shadow: none;
}

.dataTables_length > label {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 0.875rem;
  letter-spacing: 0.01071em;
  line-height: 1.43;
}

.dataTables_info,
.dataTables_length .custom-select {
  font-size: 0.875rem;
  letter-spacing: 0.01071em;
  line-height: 1.43;
}

.dataTables_paginate .page-item > .page-link {
  border-radius: 50%;
  margin-left: 0;
  padding: 12px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.dataTables_paginate .page-item.disabled > .page-link {
  opacity: 0.3;
}

.dataTables_paginate > .pagination {
  margin: 0 0 0 20px;
  padding: 0;
}

.dataTable.fixedHeader-floating, .dataTable.fixedHeader-locked {
  border-bottom: 1px solid #e1e1e1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.dataTable.fixedHeader-floating {
  position: fixed !important;
}

.dataTable.fixedHeader-locked {
  position: absolute !important;
}

@media print {
  .dataTable.fixedHeader-floating {
    display: none;
  }
}

.dtr-control:focus {
  outline: 0;
}

table.dataTable.DTCR_clonedTable {
  background-color: #ebebeb;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  position: absolute !important;
  z-index: 40;
}

div.DTCR_pointer {
  background-color: #757575;
  width: 1px;
  z-index: 39;
}

@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}

div.dt-button-info {
  background-color: #333333;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  height: 3rem;
  letter-spacing: 0.017857em;
  line-height: 1.25rem;
  margin: 0.5rem;
  max-width: 42rem;
  min-width: 21.5rem;
  overflow: hidden;
  position: fixed;
  top: auto;
  bottom: 0;
  left: calc(50% - 172px);
  text-align: left;
  width: auto;
  z-index: 239;
}

div.dt-button-info h2 {
  display: none;
}

div.dt-button-info > div {
  padding: 1rem;
}

/*
div.dt-button-collection-title {
  font-size: 0.9em;
  padding: 0.3em 0 0.5em;
  text-align: center;

  &:empty {
    display: none;
  }
}

div.dt-button-collection {
  position: absolute;
  z-index: 2001;

  &.two-column {
    width: 400px;
  }

  &:last-child {
    column-gap: 8px;
    display: block !important;

    > * {
      break-inside: avoid;
    }
  }

  div.dropdown-menu {
    display: block;
    min-width: 100%;
    z-index: 2002;
  }

  div.dt-button-collection-title {
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

div.dt-button-collection.fixed {
  border-radius: 0;
  margin-left: -75px;
  position: fixed;
  top: 50%;
  left: 50%;

  &.two-column {
    margin-left: -200px;
  }

  &.three-column {
    margin-left: -225px;
  }

  &.four-column {
    margin-left: -300px;
  }
}

div.dt-button-collection.two-column> :last-child {
  padding-bottom: 1px;
  column-count: 2;
}

div.dt-button-collection.three-column {
  width: 450px;
}

div.dt-button-collection.three-column> :last-child {
  padding-bottom: 1px;
  column-count: 3;
}

div.dt-button-collection.four-column {
  width: 600px;
}

div.dt-button-collection.four-column> :last-child {
  padding-bottom: 1px;
  column-count: 4;
}

div.dt-button-collection .dt-button {
  border-radius: 0;
}

div.dt-button-collection.fixed {
  max-width: none;
}

div.dt-button-collection.fixed:before,
div.dt-button-collection.fixed:after {
  display: none;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }

  div.dt-buttons a.btn {
    float: none;
  }
}

div.dt-buttons button.btn.processing,
div.dt-buttons div.btn.processing,
div.dt-buttons a.btn.processing {
  color: rgba(0, 0, 0, 0.2);
}

div.dt-buttons button.btn.processing:after,
div.dt-buttons div.btn.processing:after,
div.dt-buttons a.btn.processing:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: ' ';
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
}
*/
table.dataTable.dtr-inline td,
table.dataTable.dtr-inline th {
  white-space: nowrap;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child {
  padding: 8px 8px 8px 42px;
}

.dataTable.dtr-inline.collapsed > tbody > tr > td.child::before,
.dataTable.dtr-inline.collapsed > tbody > tr > th.child::before,
.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty::before {
  display: none;
}

.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > td:first-child,
.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > th:first-child {
  align-items: center;
  display: flex;
  padding-left: 8px;
}

.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px;
}

.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child::before,
.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child::before {
  border-radius: 14px;
  height: 14px;
  line-height: 14px;
  top: 5px;
  left: 4px;
  text-indent: 3px;
  width: 14px;
}

.dataTable.dtr-column > tbody > tr > td.control,
.dataTable.dtr-column > tbody > tr > th.control {
  cursor: pointer;
  position: relative;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > td.dtr-control,
table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > th.dtr-control {
  padding-left: 42px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > td.dtr-control::before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > th.dtr-control::before {
  background: no-repeat center/24px url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"%3E%3Cpath d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/%3E%3C/svg%3E');
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  content: '';
  height: 24px;
  line-height: 0;
  margin-top: 0;
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  transform: rotate(0deg);
  transition: transform 200ms linear;
  width: 24px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control::before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th.dtr-control::before {
  background-color: transparent;
  transform: rotate(-180deg);
}

table.dataTable > tbody > tr.child:hover {
  background-color: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  border-bottom: 0;
  padding: 6px 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: none;
  display: flex;
}

.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  font-weight: 500;
  margin-right: 16px;
  min-width: 75px;
  text-transform: lowercase;
}

.dataTable > tbody > tr.child span.dtr-title::first-letter {
  text-transform: uppercase;
}

div.dtr-modal {
  height: 100%;
  padding: 10em 1em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

div.dtr-modal div.dtr-modal-display {
  background-color: #f5f5f7;
  border: 1px solid #000000;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
  height: 50%;
  margin: auto;
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  z-index: 102;
}

div.dtr-modal div.dtr-modal-content {
  padding: 1em;
  position: relative;
}

div.dtr-modal div.dtr-modal-close {
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  cursor: pointer;
  height: 22px;
  position: absolute;
  top: 6px;
  right: 6px;
  text-align: center;
  width: 22px;
  z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}

div.dtr-bs-modal table.table tr:first-child td {
  border-top: none;
}

.flatpickr-calendar {
  animation: none;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  display: none;
  opacity: 0;
  position: absolute;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  max-height: 40rem;
  opacity: 1;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.open.animate {
  animation: fpFadeInDown 250ms cubic-bezier(0, 0, 0.2, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 4px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  display: block;
  z-index: 999;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 1px solid #d7d7d7;
  height: 40px;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  align-items: center;
  display: flex;
  padding: 1rem 0 12px;
}

.flatpickr-months .flatpickr-month {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  flex: 1;
  margin-left: 2rem;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  text-decoration: none;
  width: 24px;
  z-index: 3;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.flatpickr-months .flatpickr-prev-month:active,
.flatpickr-months .flatpickr-next-month:active {
  background-color: rgba(0, 0, 0, 0.24);
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  height: 14px;
  width: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  fill: #757575;
  stroke: #757575;
  stroke-width: 1px;
}

.flatpickr-months .flatpickr-prev-month {
  order: 1;
}

.flatpickr-months .flatpickr-next-month {
  margin: 0 1rem 0 24px;
  order: 2;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  cursor: pointer;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  padding: 0 4px 0 2px;
  position: absolute;
  right: 0;
  width: 14px;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp::after {
  border-right: 4px solid transparent;
  border-bottom: 4px solid #757575;
  border-left: 4px solid transparent;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown::after {
  border-top: 4px solid #757575;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  top: 40%;
}

.numInputWrapper span::after {
  content: '';
  display: block;
  position: absolute;
}

.numInputWrapper span:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.numInputWrapper span:active {
  background-color: rgba(0, 0, 0, 0.24);
}

.numInputWrapper span svg {
  height: auto;
  width: inherit;
}

.numInputWrapper span svg path {
  fill: #757575;
}

.numInputWrapper:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.numInputWrapper:focus {
  background-color: rgba(0, 0, 0, 0.12);
}

.flatpickr-current-month {
  color: inherit;
  display: inline-block;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  width: auto;
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.flatpickr-current-month .numInputWrapper {
  border-radius: 12px;
  display: inline-block;
  height: 24px;
  width: 60px;
}

.flatpickr-current-month .numInputWrapper span.arrowUp::after {
  border-bottom-color: #757575;
}

.flatpickr-current-month .numInputWrapper span.arrowDown::after {
  border-top-color: #757575;
}

.flatpickr-current-month input.cur-year {
  appearance: textfield;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0 0 0 0.5rem;
  vertical-align: initial;
}

.flatpickr-current-month input.cur-year[disabled] {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M7 10l5 5 5-5z"/%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3c/svg%3e');
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  height: auto;
  margin: -1px 0 0 -4px;
  outline: 0;
  padding: 4px 28px 4px 4px;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: 0;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  outline: 0;
  padding: 8px 0;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.flatpickr-weekdays {
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 28px;
  overflow: hidden;
  width: 100%;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
  padding: 0 16px;
}

.flatpickr-weekday {
  color: transparent;
  cursor: default;
  display: block;
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  transform: translateX(15px);
  user-select: none;
}

.flatpickr-weekday::first-letter {
  color: rgba(0, 0, 0, 0.54);
}

.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 307.875px;
  min-width: 307.875px;
  opacity: 1;
  outline: 0;
  padding: 0 16px 8px;
  text-align: left;
  transform: translate3d(0, 0, 0);
  width: 307.875px;
  /* box-sizing: border-box; */
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  align-items: center;
  border-radius: 150px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: flex;
  /* flex-basis: 14.2857143%; */
  font-size: 13px;
  font-weight: 400;
  height: 39px;
  justify-content: center;
  max-width: 39px;
  outline: 0;
  position: relative;
  user-select: none;
  width: 14.2857143%;
}

.flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover {
  background-color: #f7f2fe;
  color: inherit;
  cursor: pointer;
  outline: 0;
  z-index: 2;
}

.flatpickr-day.today {
  border: 1px solid #5a5a5a;
}

.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: #757575;
}

.flatpickr-day.inRange, .flatpickr-day.inRange:hover {
  background-color: #e6d6fc;
  border-radius: 0;
  box-shadow: -5px 0 0 #e6d6fc, 5px 0 0 #e6d6fc;
}

.flatpickr-day:nth-of-type(7n + 1).inRange {
  border-radius: 50% 0 0 50%;
}

.flatpickr-day:nth-of-type(7n).inRange {
  border-radius: 0 50% 50% 0;
}

.flatpickr-day.startRange, .flatpickr-day.startRange:hover {
  box-shadow: none;
}

.flatpickr-day.startRange + .flatpickr-day.inRange {
  box-shadow: -10px 0 0 #e6d6fc, 5px 0 0 #e6d6fc;
}

.flatpickr-day.endRange, .flatpickr-day.endRange:hover {
  box-shadow: -10px 0 0 #e6d6fc;
}

.flatpickr-day:nth-of-type(7n).startRange, .flatpickr-day:nth-of-type(7n+1).endRange {
  box-shadow: none !important;
}

.flatpickr-day.selected, .flatpickr-day.selected.inRange {
  background-color: #6200ee;
  border-radius: 50%;
  color: #ffffff;
  z-index: 3;
}

.flatpickr-day.selected.startRange + .flatpickr-day.selected.endRange {
  box-shadow: none;
}

.flatpickr-day.selected.startRange {
  z-index: 4;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  background-color: transparent;
  color: #b3b3b3;
  cursor: default;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6d6fc, 5px 0 0 #e6d6fc;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 #e6e6e6;
  padding: 0 12px;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  line-height: 28px;
  width: 100%;
}

.flatpickr-weekwrapper .flatpickr-day,
.flatpickr-weekwrapper .flatpickr-day:hover {
  background-color: transparent;
  color: #b3b3b3;
  cursor: default;
  max-width: none;
  width: 100%;
}

.flatpickr-innerContainer {
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
}

.flatpickr-time {
  display: flex;
  line-height: 40px;
  max-height: 40px;
  outline: 0;
  overflow: hidden;
  text-align: center;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time::after {
  clear: both;
  content: '';
  display: table;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  float: left;
  height: 40px;
  width: 40%;
}

.flatpickr-time .numInputWrapper .arrowUp::after {
  border-bottom-color: #757575;
}

.flatpickr-time .numInputWrapper .arrowDown::after {
  border-top-color: #757575;
}

.flatpickr-time input {
  appearance: textfield;
  background-color: transparent;
  border: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 700;
  height: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  /* box-sizing: border-box; */
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  align-self: center;
  color: #393939;
  float: left;
  font-weight: 700;
  height: inherit;
  line-height: inherit;
  user-select: none;
  width: 2%;
}

.flatpickr-time .flatpickr-am-pm {
  color: #6200ee;
  cursor: pointer;
  font-size: 0.875rem;
  width: 18%;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover {
  background-color: rgba(255, 255, 255, 0.04);
}

.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background-color: rgba(98, 0, 238, 0.16);
  color: #6200ee;
  outline: 0;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.irs {
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  user-select: none;
}

.irs-line {
  display: block;
  outline: 0 !important;
  overflow: hidden;
  position: relative;
}

.irs-bar {
  display: block;
  position: absolute;
  left: 0;
  width: 0;
}

.irs-shadow {
  display: none;
  position: absolute;
  left: 0;
  width: 0;
}

.irs-handle {
  box-sizing: border-box;
  cursor: default;
  position: absolute;
  z-index: 1;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min,
.irs-max {
  cursor: default;
  position: absolute;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from,
.irs-to,
.irs-single {
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
}

.irs-grid {
  display: none;
  height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  background-color: #000000;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  color: #000000;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  white-space: nowrap;
}

.irs-disable-mask {
  background-color: rgba(0, 0, 0, 0);
  cursor: default;
  height: 100%;
  position: absolute;
  top: 0;
  left: -1%;
  width: 102%;
  z-index: 2;
}

.lt-ie9 .irs-disable-mask {
  background-color: #000000;
  cursor: not-allowed;
  filter: alpha(opacity=0);
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  background: none !important;
  border-color: transparent !important;
  border-style: solid !important;
  font-size: 0 !important;
  height: 0 !important;
  line-height: 0 !important;
  margin: 0 !important;
  outline: 0 !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  z-index: -9999 !important;
}

.irs--material {
  height: 3rem;
}

.irs--material.irs-with-grid {
  height: 3.75rem;
}

.irs--material .irs {
  font-weight: 500;
}

.irs--material .irs-line {
  background-color: rgba(98, 0, 238, 0.24);
  border-radius: 0.125rem;
  height: 0.25rem;
  top: 26px;
}

.irs--material .irs-line:hover {
  cursor: pointer;
}

.irs--material .irs-bar {
  background-color: #6200ee;
  border-radius: 0.1875rem;
  height: 0.375rem;
  top: 25px;
}

.irs--material .irs-bar:hover {
  cursor: pointer;
}

.irs--material .irs-bar:hover::after {
  content: '';
  position: absolute;
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}

.irs--material .irs-shadow {
  background-color: rgba(0, 0, 0, 0.25);
  height: 2px;
  bottom: 26px;
}

.irs--material .irs-handle {
  background-color: #6200ee;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  height: 1.25rem;
  top: 18px;
  transition: box-shadow 100ms ease-out;
  width: 1.25rem;
}

.irs--material .irs-handle::before {
  content: '';
  height: 3rem;
  position: absolute;
  top: -15px;
  left: -15px;
  width: 3rem;
}

.irs--material .irs-handle:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.12);
  cursor: pointer;
  transition: box-shadow 100ms ease-out;
}

.irs--material .irs-handle.state_hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.24);
}

.irs--material .irs-min,
.irs--material .irs-max {
  border-radius: 4px;
  color: #999999;
  font-size: 0.625rem;
  line-height: 1.333;
  padding: 2px 4px;
  top: 0;
  text-shadow: none;
}

.irs--material .irs-from,
.irs--material .irs-to,
.irs--material .irs-single {
  background-color: #6d6d6d;
  border-radius: 2px;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1.333;
  padding: 6px 8px;
  text-shadow: none;
  transform: translateY(-16px);
  z-index: 10;
}

.irs--material .irs-from::before,
.irs--material .irs-to::before,
.irs--material .irs-single::before {
  border: 3px solid transparent;
  border-top-color: #6d6d6d;
  content: '';
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  position: absolute;
  bottom: -6px;
  left: 50%;
  width: 0;
}

.irs--material .irs-grid {
  height: 25px;
  transform: translateY(-9px);
}

.irs--material .irs-grid-pol {
  background-color: rgba(98, 0, 238, 0.54);
  border-radius: 50%;
  height: 0.25rem;
}

.irs--material .irs-grid-text {
  color: #999999;
  font-size: 0.625rem;
}

.irs--material.irs-disabled {
  opacity: 1;
}

.irs--material.irs-disabled .irs-line,
.irs--material.irs-disabled .irs-bar,
.irs--material.irs-disabled .irs-from,
.irs--material.irs-disabled .irs-to,
.irs--material.irs-disabled .irs-single,
.irs--material.irs-disabled .irs-handle {
  background-color: #8c8c8c;
}

.irs--material.irs-disabled .irs-from::before,
.irs--material.irs-disabled .irs-to::before,
.irs--material.irs-disabled .irs-single::before {
  display: none;
}

.irs--material.irs-disabled .irs-grid-pol {
  background-color: rgba(215, 215, 215, 0.54);
}

.select2-container {
  display: block;
  margin: 0;
  max-width: 100%;
  min-width: 200px;
  position: relative;
}

.select2-container *:focus {
  outline: 0;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  user-select: none;
}

.select2-container .select2-selection--single .select2-selection__placeholder {
  color: rgba(0, 0, 0, 0.54);
}

.select2-container .select2-selection--single .select2-selection__arrow {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' opacity='.54' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat 50%;
  height: 24px;
  margin-left: auto;
  order: 12;
  pointer-events: none;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  width: 24px;
}

.select2-container.select2-container--open .select2-selection__arrow {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%236200ee' fill-rule='evenodd' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat 50%;
  transform: rotate(180deg) translateY(-5px);
}

.select2-container .select2-search--dropdown .select2-search__field {
  background-color: #f5f5f5;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px 4px 0 0;
}

.select2-container .select2-results__message {
  color: rgba(0, 0, 0, 0.54);
}

.select2-container[dir='rtl'] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container.select2-container--open .select2-selection--multiple::after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' fill='%236200ee' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat 50%;
  transform: rotate(180deg) translateY(-5px);
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  display: block;
  flex-wrap: wrap;
  user-select: none;
}

.select2-container .select2-selection--multiple::after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' opacity='.54' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat 50%;
  content: '';
  height: 24px;
  margin-left: auto;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  width: 24px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__rendered:not(:empty) {
  margin: -2px 0;
}

.select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 1rem;
  cursor: pointer;
  display: inline-flex;
  float: left;
  font-size: 0.875rem;
  height: 2rem;
  justify-content: center;
  letter-spacing: 0.017857em;
  line-height: 1.25rem;
  margin-top: 0.3em;
  margin-right: 5px;
  padding: 0 0.75rem;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
}

.select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice:last-of-type {
  animation: opacity 0.3s linear;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  background-image: url('data:image/svg+xml;charset=utf8,%3csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"%3e%3cpath fill="%23666" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/%3e%3c/svg%3e');
  border: 0;
  height: 18px;
  margin-right: -0.25rem;
  margin-left: 0.5rem;
  order: 12;
  width: 18px;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #000000;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove span {
  display: none;
}

.select2-container .select2-search--inline .select2-search__field {
  background-color: transparent;
  border: none;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  display: none;
}

.select2-container .select2-selection {
  align-items: center;
  background-clip: padding-box;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px 4px 0 0;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  font-size: 1rem;
  letter-spacing: 0.009375em;
  line-height: 1.5;
  overflow: hidden;
  padding: 0.375rem 0 calc(0.375rem - 1px);
  text-overflow: ellipsis;
  transition: border-color 15ms linear, box-shadow 15ms linear;
  white-space: nowrap;
  width: 100%;
  will-change: border-color, box-shadow;
}

.select2-container .select2-selection::before {
  background-color: currentColor;
  color: #6200ee;
  content: '';
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}

.select2-container .select2-selection:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.select2-container.select2-container--open .select2-selection::before {
  transform: scaleX(1);
}

select.is-invalid ~ .select2-container .select2-selection,
.was-validated select:invalid ~ .select2-container .select2-selection {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='%23b00020' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'/%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  border-color: #b00020;
}

.select2-container .select2-selection__clear {
  background-color: #c8c8c8;
  border-radius: 100%;
  color: #ffa500;
  float: right;
  height: 0.9em;
  line-height: 0.75em;
  margin-top: 0.7em;
  margin-right: 0.3em;
  padding-left: 0.15em;
  width: 0.9em;
}

.select2-container .select2-selection__clear:hover {
  background-color: #afafaf;
}

.select2-container.select2-container--disabled .select2-selection {
  border-bottom-style: dotted;
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.select2-container.select2-container--disabled .select2-selection:hover {
  border-color: rgba(0, 0, 0, 0.42);
}

.select2-container.select2-container--disabled .select2-selection__clear {
  display: none;
}

.select2-dropdown {
  animation: opacity 0.03s linear, select-menu-scale 0.12s cubic-bezier(0, 0, 0.2, 1);
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: absolute;
  left: -100000px;
  transform-origin: center top;
  width: 100%;
  z-index: 80;
}

.select2-container--open .select2-dropdown {
  top: 1px;
  left: 0;
}

.select2-container--open .select2-dropdown.select2-dropdown--above {
  border-radius: 4px 4px 0 0;
  top: -1px;
  transform-origin: center bottom;
}

@keyframes select-menu-scale {
  0% {
    transform: scale(0.87);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.select2-results {
  display: block;
}

.select2-results__options {
  font-size: 1rem;
  list-style: none;
  margin: 0.5rem 0 0 0;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 0 0 0.5rem 0;
}

.select2-results__options.select2-results__options--nested {
  margin: 0;
  overflow-y: hidden;
  padding: 0;
}

.select2-results__options.select2-results__options--nested > .select2-results__option {
  padding-left: 1.5rem;
}

.select2-results__option,
.select2-results__group {
  align-items: center;
  display: flex;
  min-height: 3rem;
  padding: 0 1rem;
  position: relative;
}

.select2-results__option {
  letter-spacing: 0.009375em;
  transition: background-color, color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
}

.select2-results__option--highlighted.select2-results__option--selectable {
  background-color: rgba(0, 0, 0, 0.04);
}

.select2-results__option--highlighted.select2-results__option--selectable:active {
  background-color: rgba(0, 0, 0, 0.24);
}

.select2-results__option--selected {
  background-color: rgba(0, 0, 0, 0.12);
}

.select2-results__option--selected.select2-results__option--highlighted {
  background-color: rgba(0, 0, 0, 0.16);
}

.select2-results__option.select2-results__option--disabled {
  color: rgba(0, 0, 0, 0.38);
}

.select2-results__option--group {
  display: block;
  padding: 0;
}

.select2-results__group {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
}

.select2-results > .select2-results__options {
  overflow-y: auto;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-search--dropdown {
  display: block;
  padding: 5px 4px 0;
}

.select2-search--dropdown::before {
  font-size: 1.51429em;
  opacity: 0.38;
  position: absolute;
  top: 0.6em;
  left: 0.1em;
}

.select2-search--dropdown .select2-search__field {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 20px;
  box-sizing: border-box;
  line-height: normal;
  padding: 10px 16px 12px;
  padding-left: 1.5em;
  width: 100%;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  background-color: #ffffff;
  border: 0;
  filter: alpha(opacity=0);
  height: auto;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  z-index: 99;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.input-group .select2-container {
  flex-grow: 1;
}

.input-group-prepend ~ .select2-container .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .select2-container:not(:last-child) .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--outlined .select2-selection--single .select2-selection__placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.select2-container--outlined.select2-container--open .select2-selection {
  border-color: #6200ee;
  box-shadow: inset 2px 2px 0 -1px #6200ee, inset -2px -2px 0 -1px #6200ee;
}

.select2-container--outlined.select2-container--open .select2-selection::before {
  transform: scaleX(0);
}

.select2-container--outlined .select2-selection {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  line-height: 1.5;
  min-height: 3.5rem;
  padding: 14px 16px 12px;
}

.select2-container--outlined.select2-container--disabled .select2-selection {
  border-bottom-style: solid;
}

.select2-container--filled .select2-selection--single .select2-selection__placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.select2-container--filled .select2-selection {
  background-color: whitesmoke;
  padding: 20px 16px 11px;
}

.select2-container--filled .select2-selection:hover {
  background-color: #ededed;
}

.select2-container--filled .select2-selection:active {
  background-color: #dbdbdb;
}

.select2-container--filled.select2-container--disabled .select2-selection {
  border-bottom-style: solid;
}

textarea.textarea-autosize {
  height: 2.25rem;
  min-height: 2.25rem;
  overflow-y: hidden;
  resize: none;
}

textarea.textarea-autosize.form-control-lg {
  height: 3.75rem;
  min-height: 3.75rem;
}

textarea.textarea-autosize.form-control-sm {
  height: 2rem;
  min-height: 2rem;
}
